<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/empresas-parceiras"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <EmpresaParceiraFormulario @send="criar" />
      </div>
    </div>
    <AlertSuccess
      :dialog="success"
      dialogMessage="Empresa criada com sucesso"
      @close="goToEmpresas"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { criarEmpresaParceira } from "@/services/empresas-parceiras.js";
import EmpresaParceiraFormulario from "@/components/empresas-parceiras/EmpresaParceiraFormulario.vue";
export default {
  name: "EmpresaParceiraCriar",
  components: { EmpresaParceiraFormulario },
  data: () => ({
    breadcrumbs: [
      {
        text: "Empresas Parceiras",
        disabled: false,
        to: "/empresas-parceiras",
      },
      {
        text: "Criar Empresa Parceira",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  methods: {
    async criar(event) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await criarEmpresaParceira(event).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
      } catch (e) {
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    goToEmpresas() {
      this.sucess = false;
      this.$router.push({ name: "EmpresasParceiras" });
    },
  },
};
</script>

<style></style>
